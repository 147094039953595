const landingSteps = [
  //0
  {
    selector: ".tour1",
    placement: "top",

    content: (
      <div className="d-flex flex-column align-items-center">
        <h3 className="text-dark bg-light tour-text p-2">Audio file upload</h3>
        <p className="tour-text text-dark bg-light p-2">
          You can start your uploading audio file journey from here!
        </p>
      </div>
    ),
  },
  //1
  {
    selector: ".tour2",
    placement: "top",
    content: (
      <div className="d-flex flex-column align-items-center">
        <h3 className="text-dark bg-light tour-text p-2">Text file upload</h3>
        <p className="tour-text text-dark bg-light p-2">
          Text files can be uploaded from here onwards!
        </p>
      </div>
    ),
  },
  //2
  {
    selector: ".tour-home1",
    content: (
      <div className="d-flex flex-column align-items-center">
        <h3 className="text-dark bg-light tour-text p-2">How to upload:</h3>
        <p className="tour-text text-dark bg-light p-2">
          Select your audio file to upload from here. Only .mp3, .wav, .rar, and .zip files or zip
          of these formats are supported. Make sure PDF and DOCX files are not present within the
          ZIP files.
        </p>
      </div>
    ),
  },
  //3
  {
    selector: ".tour-home2",
    placement: "top",
    content: (
      <div className="d-flex flex-column align-items-center">
        <h3 className="text-dark bg-light tour-text p-2">Sentiment analysis</h3>
        <p className="tour-text text-dark bg-light p-2">
          This chart will show you the audio files count based on customer sentiment anaylysis.
        </p>
      </div>
    ),
  },
  //4
  {
    selector: ".tour-home3",
    placement: "top",
    content: (
      <div className="d-flex flex-column align-items-center">
        <h3 className="text-dark bg-light tour-text p-2">Audio folders table</h3>
        <p className="tour-text text-dark bg-light p-2">
          This table displays folders containing audio files uploaded on each day.
        </p>
      </div>
    ),
  },
  //5
  {
    placement: "top",
    selector: ".tour-home4",
    content: (
      <div className="d-flex flex-column align-items-center">
        <h3 className="text-dark bg-light tour-text p-2">View audio files</h3>
        <p className="tour-text text-dark bg-light p-2">
          Click on eye button in action column to view audio files in the selected folder.
        </p>
      </div>
    ),
  },
  //6
  {
    placement: "top",
    selector: ".text-tour1",
    content: (
      <div className="d-flex flex-column align-items-center">
        <h3 className="text-dark bg-light tour-text p-2">How to upload:</h3>
        <p className="tour-text text-dark bg-light p-2">
          Select your text file to upload from here. Only .txt, .pdf, .zip, .rar, .xls, .xlsx, and
          .docx files are supported.
        </p>
      </div>
    ),
  },
  //7
  {
    placement: "top",

    selector: ".text-tour2",
    content: (
      <div className="d-flex flex-column align-items-center">
        <h3 className="text-dark bg-light tour-text p-2">Text file chart</h3>
        <p className="tour-text text-dark bg-light p-2">
          This chart will show you the text files sentiment anaylysis.
        </p>
      </div>
    ),
  },
  //8
  {
    placement: "top",
    selector: ".text-tour3",
    content: (
      <div className="d-flex flex-column align-items-center">
        <h3 className="text-dark bg-light tour-text p-2">Text files table</h3>
        <p className="tour-text text-dark bg-light p-2">
          This table displays list of text files uploaded till date.
        </p>
      </div>
    ),
  },
  //9
  {
    placement: "top",

    selector: ".tour-audio1",
    content: (
      <div className="d-flex flex-column align-items-center">
        <p className="tour-text text-dark bg-light p-2">The overall summary of the audio file.</p>
      </div>
    ),
  },
  //10
  {
    placement: "top",

    selector: ".tour-audio2",
    content: (
      <div className="d-flex flex-column align-items-center">
        <h3 className="text-dark bg-light tour-text p-2">Sentiment filter</h3>
        <p className="tour-text text-dark bg-light p-2">
          You can filter out the audio files based on sentiment attribute.
        </p>
      </div>
    ),
  },
  //11
  {
    placement: "top",

    selector: ".tour-audio3",
    content: (
      <div className="d-flex flex-column align-items-center">
        <h3 className="text-dark bg-light tour-text p-2">Audio files table</h3>
        <p className="tour-text text-dark bg-light p-2">
          This table displays complete info regarding all audio files in your table .
        </p>
      </div>
    ),
  },
  //12
  {
    placement: "top",

    selector: ".tour-audio4",
    content: (
      <div className="d-flex flex-column align-items-center">
        <h3 className="text-dark bg-light tour-text p-2">Action tab</h3>
        <p className="tour-text text-dark bg-light p-2">
          You can view report for each file in this tab.
        </p>
      </div>
    ),
  },
  //13
  {
    placement: "top",

    selector: ".tour-audio5",
    content: (
      <div className="d-flex flex-column align-items-center">
        <h3 className="text-dark bg-light tour-text p-2">Rename</h3>
        <p className="tour-text text-dark bg-light p-2">
          Click on edit icon to rename the audio file. A meaningful name without special characters
          is acceptable.
        </p>
      </div>
    ),
  },
  //14
  {
    placement: "top",

    selector: ".tour-audio6",
    content: (
      <div className="d-flex flex-column align-items-center">
        <h3 className="text-dark bg-light tour-text p-2">Delete</h3>
        <p className="tour-text text-dark bg-light p-2">
          The delete button will in this column will delete the respective audio file.
        </p>
      </div>
    ),
  },
];

export default landingSteps;
