import React from "react";
import Stack from "./MainStack/Stack";
import AuthProvider from "./context/AuthContext";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <>
      <AuthProvider>
        <Stack/>
      </AuthProvider>
    </>
  );
}

export default App;
