import React from 'react'
import VoxinsightLogo from '../../assets/img/voxinsight-new-logo.png'

const SplashScreen = () => {
  return (
    <div className='splash-screen d-flex align-items-center justify-content-center'>
      <img src={VoxinsightLogo} alt="Logo" className='col-lg-2 col-md-3 col-6'/>
    </div>
  )
}

export default SplashScreen